.widget-logo {
    --height: 200vh;
    
    --padding-v: var(--padding-xxxxxxl);
    --padding-h: var(--padding-s);
    
    --width: #{rem(600px)};
    --color: var(--primary-color);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxxl);
        --padding-h: var(--padding-xs);
        --height: 50vh;
        --width: 50%;
    }

    
}
    
.widget-logo {
    padding: 0 var(--padding-h);
    margin: var(--padding-v) 0;
    height: var(--height);

    .wrapper {
        left: 0;
        position: absolute;
        top: 0;
        height: 100vh;
        width: 100%;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .simbolo {
        width: var(--width);

        svg {
            width: 100%;
            height: auto;
            fill: var(--color);
            transition: fill .3s ease-out;
        }
    }

    @media (max-width: $smartphone) {
        .wrapper {
            height: 100%;
        }
    }
}