.widget-landing,
.widget-landing-info {
    --bg: var(--primary-bg);
    --caption-max-width: #{rem(330)};
    --media-width: #{rem(448)};
    --media-height: #{rem(500)};
    --content-max-width: #{rem(920)};
    
    @media (min-width: $smartphone) {
        --padding: var(--padding-s);    
        --media-width: #{rem(448)};
        --media-height: #{rem(500)};
    }
    
    @media (max-width: $smartphone) {
        --padding: var(--padding-xs);
        --media-width: 70vw;
        --media-height: 100vw;
    }

    --height-vh: 100vh;
    --height-svh: 100dvh;
    --height: var(--height-svh, var(--height-vh));

    .--safari & {
        --height: var(--height-vh);
    }
}

.widget-landing {
    position: relative;
    width: 100%;
    height: var(--height);
    padding: var(--padding);
    display: flex;
    align-items: flex-end;
    
    > .media-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 120%;
        overflow: hidden;
        opacity: .9;
     
        img,
        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            transform-origin: 50% 25%;
        }
    }

    > .grad {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 25%;
        background: linear-gradient(0deg, rgba(26, 26, 30, 1) 0%, rgba(26, 26, 30, 0) 100%);

        &::after {
            content: '';
            position: absolute;
            top: 99%;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(26, 26, 30, 1);
        }
    }

    > .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 120%;
        background-color: var(--bg);        
    }

    > .caption {
        position: relative;
        @include font-sans-base();
        max-width: var(--caption-max-width);

        .left {
            opacity: 0;
        }
    }

    > .content {
        position: absolute;
        top: calc(50% + 11vw);
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        > .holder {
            overflow: clip;
            transform: translateY(250%);
        }
             
        figure {
            position: relative;
            width: var(--media-width);
            height: var(--media-height);
            overflow: clip;
            transform: scale3d(2,2,2);
        }

        video,
        img {
            position: absolute;
            top: 0;
            left: -5%;
            width: 110%;
            height: 100%;
            object-fit: cover;
            object-position: center center; 
        }

        @media (max-width: $smartphone) {
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.widget-landing-info {
    position: relative;
    width: 100%;
    padding: var(--padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 10vw 0;
    
    .text-small {
        @include font-sans-s();
        text-align: center;
        margin: 9em 0 2.6em;
        max-width: var(--content-max-width);
        
        @media (max-width: $smartphone) {
            margin: 0 0 2.6em;
        }
    }

    .text {
        @include font-sans-l();
        text-align: center;
        max-width: var(--content-max-width);
        //text-wrap: balance;
    }
}