@use "sass:math";

:root {
    --font-sans: 'FKGrotesk-Regular', sans-serif;
    --font-serif: 'OTNeglige-L', serif;
    --font-sans-bold: 'FKGrotesk-Regular', sans-serif;
    --font-sans-italic: 'FKGrotesk-Regular', sans-serif;

    --font-size-xxxxl: #{math.div(400px, 16px) * 1rem};
    --font-size-xxxl: #{math.div(200px, 16px) * 1rem};
    --font-size-xxl: #{math.div(120px, 16px) * 1rem};
    --font-size-xl-2: #{math.div(100px, 16px) * 1rem};
    --font-size-xl: #{math.div(50px, 16px) * 1rem};
    --font-size-l: #{math.div(24px, 16px) * 1rem};
    --font-size-m: #{math.div(18px, 16px) * 1rem};
    --font-size-base: #{math.div(15px, 16px) * 1rem};
    --font-size-s: #{math.div(12px, 16px) * 1rem};
    --font-size-xs: #{math.div(10px, 16px) * 1rem};

    --zoom: 1;
    --line-height-sans: 1.26;
    --line-height-serif: .83;
    --letter-spacing: -0.06em;

    --min-font-size: 10px;
    --max-font-size: 48px;
    --font-size: clamp(var(--min-font-size), 1.1111vw * var(--zoom), var(--max-font-size));
    font-size: var(--font-size);
    
    @media (max-width: $smartphone) {
        --font-size-xxxxl: #{math.div(68px, 16px) * 1rem};
        --font-size-xxxl: #{math.div(64px, 16px) * 1rem};
        --font-size-xxl: #{math.div(38px, 16px) * 1rem};
        --font-size-xl-2: #{math.div(36px, 16px) * 1rem};
        --font-size-xl: #{math.div(28px, 16px) * 1rem};
        --font-size-l: #{math.div(16px, 16px) * 1rem};
        --font-size-m: #{math.div(14px, 16px) * 1rem};
        --font-size-base: #{math.div(12px, 16px) * 1rem};
        --font-size-s: #{math.div(10px, 16px) * 1rem};
        --font-size-xs: #{math.div(8px, 16px) * 1rem};

        --font-size: 16px;
    }
}
@mixin font-serif($line-height: 0) {
    font-family: var(--font-serif);
    font-weight: normal;
    text-transform: uppercase;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-serif);  }
}
@mixin font-sans($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-bold($line-height: 0) {
    font-family: var(--font-sans-bold);
    font-weight: 500;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-italic($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: 200;
    font-style: italic;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-sans-xs($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    font-size: var(--font-size-xs);
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-base($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    font-size: var(--font-size-base);
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-s($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    font-size: var(--font-size-s);
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-m($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    font-size: var(--font-size-m);
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-l($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    font-size: var(--font-size-l);
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-serif-base($line-height: 0) {
    font-family: var(--font-serif);
    font-weight: normal;
    font-size: var(--font-size-xl);
    text-transform: uppercase;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-serif);  }
}
@mixin font-serif-m($line-height: 0) {
    font-family: var(--font-serif);
    font-weight: normal;
    font-size: var(--font-size-xl-2);
    text-transform: uppercase;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-serif);  }
}
@mixin font-serif-l($line-height: 0) {
    font-family: var(--font-serif);
    font-weight: normal;
    font-size: var(--font-size-xxl);
    text-transform: uppercase;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-serif);  }
}
@mixin font-serif-xl($line-height: 0) {
    font-family: var(--font-serif);
    font-weight: normal;
    font-size: var(--font-size-xxxl);
    text-transform: uppercase;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-serif);  }
}
@mixin font-serif-xxl($line-height: 0) {
    font-family: var(--font-serif);
    font-weight: normal;
    font-size: var(--font-size-xxxxl);
    text-transform: uppercase;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-serif);  }
}
