.block-title-gallery {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-s);
    --margin-v: var(--padding-xxxl);
    --max-width: #{rem(800)};

    .--pos-1 {
        justify-content: flex-start;
    }
    
    .--pos-2 {
        justify-content: flex-end;
    }
    
    .--pos-3 {
        justify-content: flex-end;
    }

    @media (max-width: $smartphone) {
        --margin-v: var(--padding-xxl);
        --padding-h: var(--padding-xs);
    }

    @media (min-width: $smartphone) {
        .--size-1 {
            --width: #{rem(212)};
        }

        .--size-2 {
            --width: #{rem(330)};
        }

        .--size-3 {
            --width: #{rem(448)};
        }
        
        .--offset-1 {
            --offset: var(--padding-s);
        }
        
        .--offset-2 {
            --offset: var(--padding-m);
        }
        
        .--offset-3 {
            --offset: 0;
        }
    }
}
    
.block-title-gallery {
    margin: var(--margin-v) 0;
    
    .title {
        @include z-index(1);
        position: relative;
        max-width: var(--max-width);

        > div {
            @include font-serif-l();
        }
        
        .label {
            @include font-sans-s();
        }
    }
    
    @media (max-width: $smartphone) {
        .title {
            padding: 0 var(--padding-h);
            margin-bottom: var(--padding-m);
            
            .label {
                margin-bottom: var(--padding-xxs);
                display: block;
            }
        }

        &__images {
            -webkit-overflow-scrolling: touch;
            overflow: hidden;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            gap: var(--padding-xs);
            display: flex;
            height: 100%;
            width: auto;
            padding-right: var(--padding-xs);

            &::-webkit-scrollbar {
                display: none;
            }
            
            .img-wrapper {
                width: auto;
                height: 50vh;

                scroll-snap-align: start;
                
                &:first-child {
                    padding-left: var(--padding-xs);
                }

                .img {
                    height: 100%;

                    figure {
                        padding: 0;
                        height: 100%;

                        img {
                            position: relative;
                            height: 100%;
                            width: auto;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: $smartphone) {
        padding: var(--padding-v) var(--padding-h);
        display: flex;
        justify-content: center;

        .title {
            .label {
                position: absolute;
                bottom: calc(100% + var(--padding-xs));
                left: .4em;
            }
        }

        &__images {
            @include z-index(0);
            position: absolute;
            width: 100%;
            padding: 0 var(--padding-h);

            .img-wrapper {
                margin-bottom: var(--padding-l);
                display: flex;
                padding: 0 var(--offset);

                .img {
                    width: var(--width);

                    figure {
                        display: flex;
                        justify-content: center;
                    }

                    img {
                        height: 120%;
                        width: auto;
                        left: auto;
                    }
                }
            }
        }
    }
}
