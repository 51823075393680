.block-slider-video-visor {
    --width: 100%;
    --height: 100vh;
    
    --padding-h: 0;
    --padding-v: 0;
    
    @media (max-width: $smartphone) {
        --height: 70vh;
    }

    
}

.block-slider-video-visor {
    height: var(--height);
    width: var(--width);
    user-select: none;
    padding: var(--padding-v) var(--padding-h);
    overflow: clip;

    &__slider {
        height: 100%;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        
        cursor: grab;    
        &:active {
            cursor: grabbing;
        }
    
        > .holder {
            order: 1;
            position: relative;
            height: 100%;
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
        }
    }
    
    &__item {
        position: relative;
        flex: 0 0 var(--width);
        width: var(--width);
        height: 100%;
        overflow: hidden;
    
        svg {
            height: 100%;
            width: auto;
        }

        figure {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(1.05);
            object-position: center;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }
    }

    &__controls {
        pointer-events: none;
        color: var(--primary-bg);

        @include font-sans-s(1);
        
        button {
            pointer-events: all;
            @include basic-a();
            @include font-sans-s(1);
            background-color: transparent;
            padding: 0;
        }
    }

    @media (max-width: $smartphone) {
        > div {
            height: 100%;
            width: auto;
            overflow: hidden;
        }

        .holder {
            -webkit-overflow-scrolling: touch;
            overflow: hidden;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            gap: var(--padding-xs);
            padding-right: var(--padding-xs);

            &::-webkit-scrollbar {
                display: none;
            }

        }
        
        &__item {
            min-width: 80vw;
            flex: 0 0 80vw;
            
            scroll-snap-align: start;
            
            &:first-child {
                padding-left: var(--padding-xs);
            }
        }
    }

    @media (min-width: $smartphone) {
        height: 100vh;

        &__controls {
            button {
                @include z-index(1);
                position: absolute;
                top: 0;
                height: 100%;
                width: 10vw;

                > span {
                    display: none;
                }
                
                &[scroll-slider-prev] {
                    left: 0;
                }
                
                &[scroll-slider-next] {
                    right: 0;
                }
            }
        }
    }
}

.visor-slider {
    --visor: #{rem(330)};

    @media (max-width: $smartphone) {
        display: none;
    }

    @media (min-width: $smartphone) {
        @include z-index(2);
        height: var(--visor);
        width: var(--visor);
        position: fixed;
        left: calc(50% - var(--visor) / 2);
        top: calc(50% - var(--visor) / 2);
        pointer-events: none;
        overflow: hidden;
    }

    > .holder {
        --x-origin: 50%;
        --y-origin: 50%;

        height: var(--visor);
        width: var(--visor);
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;

        transform: scale3d(1.4, 1.4, 1);
        transform-origin: var(--x-origin) var(--y-origin);
        
        > * {
            height: var(--visor);
            width: var(--visor);
            object-fit: cover;
        }
    }
}