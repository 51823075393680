.block-text-scroll {
    --padding-v: var(--padding-xxxxxl);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxxl);
        --padding-h: var(--padding-xs);
    }

    
}

.block-text-scroll {
    margin: var(--padding-v) 0;
    position: relative;
    width: 100vw;
    overflow: hidden;

    @include font-serif-xxl();
    text-transform: uppercase;

    @media (min-width: $smartphone) {
        > div {
            display: flex;
            width: auto;
            margin-left: -25vw;
            
            div {
                display: block;
                white-space: nowrap;
            }
        }
    
        > div:nth-child(odd) {
            justify-content: flex-end;
        }
    }
}
