.widget-services {
    --margin-title: 0 0 var(--padding-l);


    --padding-v: var(--padding-xxxxl);
    --padding-h: var(--padding-xxxxl);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxxl);
        --padding-h: var(--padding-xs);
    }

    
}

.widget-services {
    color: var(--primary-color);

    padding: 0 var(--padding-h);
    margin: var(--padding-v) 0;
    
    .intro {
        margin: var(--margin-title);

        .label {
            @include font-sans-s();
        }
    }

    h2 {
        @include font-serif-base();
    }

    .label {
        @include font-sans-s();
    }

    @media (max-width: $smartphone) {
        .intro {
            margin-bottom: var(--padding-l);

            .label {
                margin-bottom: var(--padding-xxs);
            }
        }

        .service-line:not(:last-child) {
            margin-bottom: var(--padding-m);
        }
    }

    @media (min-width: $smartphone) {
        .service-line {
            display: flex;

            &.--end {
                justify-content: flex-end;
            }
            
            &.--center {
                justify-content: center;
            }
            
            &.--start {
                justify-content: flex-start;
            }

            &:not(:last-child) {
                margin-bottom: 0;
            }
        }

        .intro {
            display: flex;
            gap: var(--padding-xxl);

            .label {
                margin-bottom: 0;
                flex: 0 1 rem(145);
            }
            
            h2 {
                flex: 0 1 rem(450); 
            }
        }
    }
}
