.widget-projects {
    --width: 100%;
    --height-vh: 100vh;
    --height-svh: 100dvh;
    --height: var(--height-svh, var(--height-vh));
    .--safari & {
        --height: var(--height-vh);
    }
    --color: var(--primary-color);
    --bg: var(--primary-bg);
    --visor-width: #{rem(448)};
    --visor-height: #{rem(400)};
    
    @media (min-width: $smartphone) {
        --padding: var(--padding-s);    
        --font-size-title: var(--font-size-xxxxl);
    }
    
    @media (max-width: $smartphone) {
        --padding: var(--padding-s);
        --visor-width: 80vw;
        --visor-height: 80vw;
        --font-size-title: 25vw;
    }
}

.widget-projects {
    position: relative;
    width: var(--width);
    height: var(--height);

    img {
        user-drag: none; /* Para navegadores webkit */
        -webkit-user-drag: none;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        pointer-events: none;
    }
}

.widget-projects__slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //filter: blur(3rem);
    background-color: #000;

    li {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: var(--height);
        
        figure {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            opacity: .3;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transform-origin: center center;
                
            }
        }
    }
}

.widget-projects__visor {
    position: absolute;
    top: calc((var(--height) - var(--visor-height))/2);
    left: calc((var(--width) - var(--visor-width))/2);
    width: var(--visor-width);
    height: var(--visor-height);
    overflow: hidden;
    background-color: var(--yellow);

    > a {
        @include basic-a();
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
}

.widget-projects__title {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    height: 1em;
    overflow: hidden;
    bottom: -.12em;
    left: 0;
    @include font-serif-xxl(1);
    font-size: var(--font-size-title);
    color: var(--yellow);
    text-align: center;
    pointer-events: none;

    > span {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;
        width: 100%;
            
        > span {
            display: block;
            position: relative;
            white-space: nowrap;
            height: 1em;
            padding: 0;
            width: 100%;
            text-align: center;

            &:not(:first-child) {
                opacity: 0;
            }
        }
    }
}

.widget-projects__controls {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding);
    pointer-events: none;
    font-variant-numeric: tabular-nums;

    .holder {
        display: flex;
        gap:1em;
        /*border-radius: var(--radius-s);
        background-color: rgba(255, 255, 255, .1);
        backdrop-filter: blur(var(--blur));
        padding: .4em 1em .34em;
        box-shadow: 0 .25rem 1rem rgba(0, 0, 0, 0.1);

        --border-size: .2px;
        --border-color: rgba(255, 255, 255, 0.05);
        border: var(--border-size) solid var(--border-color);*/
    }

    .counter,
    a,
    button {
        @include font-sans-s();
        color: var(--color);
        pointer-events: all;
    }

    @media (min-width: $smartphone) {
        
    }

    @media (max-width: $smartphone) {
        padding: 15vh 0 15vh;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .holder:nth-child(1) {
            justify-content: center;
            width: 100%;
        }

        .holder:nth-child(2) {
            width: 100%;
            justify-content: center;
           // padding-top: calc(80vw + var(--padding-xl));

            button {
                padding: 1em;
                display: none;
                &::before {display: none !important;}
            }

            button:nth-child(2) {
                order: 3;
            }

            .counter {
                padding: 1em;
                order: 2;
            }
        }
    }
}