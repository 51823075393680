@use "sass:math";

:root {
  --y-header: 0;

  --padding-xxxxxxl: #{math.div(375px, 16px) * 1rem};
  --padding-xxxxxl: #{math.div(240px, 16px) * 1rem};
  --padding-xxxxl: #{math.div(180px, 16px) * 1rem};
  --padding-xxxl: #{math.div(120px, 16px) * 1rem};
  --padding-xxl: #{math.div(90px, 16px) * 1rem};
  --padding-xl: #{math.div(72px, 16px) * 1rem};
  --padding-l: #{math.div(60px, 16px) * 1rem};
  --padding-m-2: #{math.div(48px, 16px) * 1rem};
  --padding-m: #{math.div(30px, 16px) * 1rem};
  --padding-s: #{math.div(24px, 16px) * 1rem};
  --padding-xs: #{math.div(12px, 16px) * 1rem};
  --padding-xxs: #{math.div(5px, 16px) * 1rem};
  --padding-xxxs: #{math.div(4px, 16px) * 1rem};

  --header-height: #{math.div(246px, 16px) * 1rem};
  
  --scrollbar-height: #{math.div(40px, 16px) * 1rem};

  --blur: 25px;
  --radius-s: #{math.div(4px, 16px) * 1rem};
}

@media (max-width: $smartphone) {
  :root {
    --header-height: #{math.div(80px, 16px) * 1rem};
  }
}
