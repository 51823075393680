// .news-card {
//     @media (max-width: $smartphone) {
//     }
// }

.news-card {
    @include basic-a();
    @include font-sans-s();

    color: var(--primary-color);
    display: block;

    .img {
        margin-bottom: var(--padding-xs);
        position: relative;
        padding-top: 100%;
        overflow: hidden;
        
        figure {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
                user-drag: none;
            }
        }

        .overlay {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;

            display: flex;
            align-items: center;
            justify-content: center;

            @include font-serif-l();
            text-transform: uppercase;

            pointer-events: none;
            opacity: 0;
        }
    }
    
    .abstract {
        @include font-sans-m();
        margin: var(--padding-xs) 0;
    }
    
    @media (max-width: $smartphone) {
        @include font-sans-xs();

        .abstract {
            @include font-sans-s();
        }
    }
    
    @media (min-width: $smartphone) {
        .img {
            margin-bottom: var(--padding-s);
        }
        
        .abstract {
            margin: var(--padding-s) 0;
        }
    }

    @include isCursor() {
        .img {
            figure img {
                transition: .2s ease-out;
            }

            .overlay {
                transition: opacity .2s ease-out;
            }
        }
        
        &:hover {
            .img {
                figure img {
                    transition: transform .5s ease-out, filter .3s ease-out;
                    transform: scale3d(1.2, 1.2, 1);
                    filter: blur(25px);
                }

                .overlay {
                    transition: opacity .4s ease-out;
                    opacity: 1;
                }
            }
        }
    }
}
