.block-title-text-image {
    --title: #{rem(684px)};
    --col-1: #{rem(448px)};
    --col-2: #{rem(212px)};

    --padding-v: var(--padding-xxxxl);
    --padding-h: var(--padding-s);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxxl);
        --padding-h: var(--padding-xs);
    }

    
}

.block-title-text-image {
    @include font-sans-base();
    padding: var(--padding-h);
    margin: var(--padding-v) 0;
    color: var(--primary-color);
    
    h2 {
        @include font-sans-l();
        max-width: var(--title);
        text-align: center;
        margin: 0 auto;
    }

    .text {
        max-width: var(--col-1);
    }

    .img {
        max-width: var(--col-2);
    }

    .text > * {
        p {
            &:not(:last-child) {
                margin-bottom: 1.5em;
            }
        }
    }

    .img {
        width: 100%;

        figure {
            display: flex; 
            justify-content: center;
        }

        img {
            height: 120%;
            width: auto;
            left: auto;
        }
    }

    @media (max-width: $smartphone) {
        .bottom {
            > div:first-child {
                margin-bottom: var(--padding-xs);
            }
        }

        .top {
            margin-bottom: var(--padding-m);
        }
    }

    @media (min-width: $smartphone) {
        .bottom {
            display: flex;
            
            > * {
                flex: 0 1 50%;
            }
    
            > div:first-child {
                display: flex;
                justify-content: flex-end;
            }
            
            > div:last-child {
                display: flex;
                justify-content: center;
            }
        }

        .top {
            margin-bottom: var(--padding-xxxl);
        }
    }
}
