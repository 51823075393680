.service-card {
    --max-width: #{rem(565px)};
    --img-width: #{rem(330px)};
    
    @media (max-width: $smartphone) {
        --max-width: 100%;
        --img-width: 100%;
    }
}

.service-card {
    @include basic-a();

    width: var(--max-width);

    .img {
        width: var(--img-width);

        figure {
            display: flex; 
            justify-content: center;
        }

        img {
            height: 120%;
            width: auto;
            left: auto;
        }
    }

    .label {
        margin-bottom: var(--padding-xs);
        color: var(--primary-color);
    }

    .name {
        @include font-serif-l();
        color: var(--primary-color);
    }

    @media (max-width: $smartphone) {
        .img {
            height: 50vh;
            display: flex;
            align-items: center;
            position: relative;
            overflow: hidden;

            figure {
                position: absolute;
            }
        }
    }

    @media (min-width: $smartphone) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        
        .label {
            margin-bottom: var(--padding-s);
        }

        > div:first-child {
            position: absolute;
            left: 0;
            @include z-index(1);
        }
    }
}