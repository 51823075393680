.block-text {
    --max-width: #{rem(920px)};
    --padding-v: var(--padding-xxxxl);
    --padding-h: var(--padding-s);
        
    &.--big {
        --max-width: #{rem(684px)};
    }

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxxl);
        --padding-h: var(--padding-xs);
    }

    
}

.block-text {
    color: var(--primary-color);
    padding: 0 var(--padding-h);
    margin: var(--padding-v) 0;

    @include font-sans-m();

    &.--big {
        @include font-sans-l();
    }
    
    > * {
        margin: 0 auto;
        max-width: var(--max-width);
        text-align: center;
    }

    p {
        &:not(:last-child) {
            margin-bottom: 1.5em;
        }
    }

    a {
        @include basic-a();
        @include font-sans-bold();
        text-decoration: underline;
        color: inherit;
    }
}
