.block-credits {
    --margin: var(--padding-xl) 0 var(--padding-xxxxl);
    --padding-h: var(--padding-s);

    @media (max-width: $smartphone) {
        --margin: var(--padding-xl) 0 var(--padding-xxxl);
        --padding-h: var(--padding-xs);
    }
}

.block-credits {
    text-align: center;
    color: var(--primary-color);

    @include font-sans-base();
    padding: 0 var(--padding-h);
    margin: var(--margin);
    
    .categ {
        @include font-sans-s();
        margin-bottom: var(--padding-xs);
    }
    
    a {
        @include basic-a();
        color: inherit;
    }
    
    > div:not(:last-child) {
        margin-bottom: var(--padding-s);
    }
}
