.index-target {
    a {
        @include basic-a();
        display: inline-flex;
        width: auto;
        color: inherit;

        > span:first-child {
            flex: 0 1 var(--padding-s);
            margin-right: 1em;
        }

        > span:nth-child(2) {
            flex: 1 0 auto;
        }
    }
}
