#Footer {      
    // --contact-margin-left: #{rem(118)};
    --contact-label-width: #{rem(58)};

    @media (min-width: $smartphone) {
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-s);
    }

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
    }
}

#Footer {
    @include font-sans-s();

    .logo {
        svg {
            display: block;
            width: 100%;
            height: auto;
            fill: var(--primary-color);
        }
    }

    padding: 0 var(--padding-h) var(--padding-v);
    color: var(--primary-color);
    
    a {
        @include basic-a();
        color: inherit;
    }

    .left {
        figure {
            @include aspect-ratio(16, 9);

            img,
            video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
    }

    .center {
        > div {
            > .content {
                @include font-sans-base();
    
                a {
                    display: block;
                }
    
                .map-link {
                    @include font-sans-s();
                    display: inline-block;
                    margin-top: var(--padding-xs);
                }
            }
        }
    }

    .right {
        > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        > *:last-child {
            margin-top: var(--padding-xs);
        }

        a:not(:last-child) {
            margin-bottom: .06em;
        }
    }

    @media (max-width: $smartphone) {
        .bottom {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: var(--padding-s);

            .center {
                grid-column: span 1;

                > div {
                    margin-bottom: 1em;
                }

                .title {
                    margin-bottom: .5em;
                }
            }

            .left,
            .right {
                grid-column: span 2;
            }

            .left {
                display: flex;
                flex-direction: column-reverse;

                > *:first-child {
                    margin-top: var(--padding-s);
                }
            }

            .right {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
            }
        }
    }

    @media (min-width: $smartphone) {
        .logo {
            margin-bottom: var(--padding-l);
        }

        .bottom { 
            display: flex;
            gap: rem(153);

            .left, .right {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
            }
            
            .left {
                flex: 0 1 rem(212);

                > *:last-child {
                    margin-top: var(--padding-xs);
                }
            }

            .center {
                > div {
                    display: flex;
                    gap: var(--padding-s);
                    margin-bottom: .06em;
                            
                    > .title {
                        flex: 0 0 var(--contact-label-width);
                        width: var(--contact-label-width);       
                    }
                    
                    &.hours {
                        margin-bottom: var(--padding-s);
                        flex-grow: 1;
                    }
    
                    > .content {
                        .map-link {
                            margin-top: var(--padding-s);
                        }
                    }
                }
            }
        }
    }
}
