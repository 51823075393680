.block-highlight-projects {
    --height-vh: 100vh;
    --height-svh: 100dvh;
    --height: var(--height-svh, var(--height-vh));

    .--safari & {
        --height: var(--height-vh);
    }
    
    --color: var(--primary-color);
    --bg: var(--primary-bg);
    --item-height: 40vh;
    --slide-width: 100vw;
    --scroll-height: 400vh;
    
    margin: 20vw 0 80vh;

    @media (min-width: $smartphone) {
        --padding: var(--padding-s);    
    }

    @media (max-width: $smartphone) {
        --padding: var(--padding-s);
    }
}

.block-highlight-projects {
    position: relative;
    width: 100%;
    height: var(--scroll-height);
    
    > .content {
        position: relative;
        height: var(--height);

        > div {
            position: absolute;
            width: 50%;
            height: 100%;
            top: 0;

            &:nth-child(1) { left: 0; }
            &:nth-child(2) { right: 0; }
        }
    }

    .fake-slider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: var(--height);
        display: flex;
        
        li {
            flex: 1 0 var(--slide-width);
            width: var(--slide-width);
        }
    }
}

.block-highlight-projects__visor {
    > figure {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        video,
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }

        figcaption {
            position: absolute;
            top: 0;
            left:0;
            width: 100%;
            height: 100%;
            @include font-sans-s();
            padding: var(--padding);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            color: var(--color);
            background-color: rgba($color: #000000, $alpha: .2);
            text-shadow: 0px 0px 1em rgba($color: #000000, $alpha: .4);
        }
    }
}

.block-highlight-projects__slider {
    li {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: var(--item-height);
        display: flex;
        justify-content: center;

        > a {
            @include basic-a();

            > figure {
                width: var(--item-height);
                height: var(--item-height);
                overflow: hidden;
    
                 img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                 }
            }
        }
    }

    > .title {
        position: absolute;
        display: inline-block;
        overflow: hidden;
        width: 100%;
        height: 1em;
        overflow: hidden;
        top: calc(50% - .5em);
        left: 0;
        @include font-serif-xl(1);
        color: var(--yellow);
        text-align: center;
        pointer-events: none;

        > span {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            overflow: hidden;
            width: 100%;
                
            > span {
                display: block;
                position: relative;
                white-space: nowrap;
                height: 1em;
                padding: 0;
                width: 100%;
                text-align: center;

                &:not(:first-child) {
                    opacity: 0;
                }
            }
        }
    }
}
