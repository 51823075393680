.block-slider-fullscreen {
    --width: 100%;
    --height: 100%;

    --padding-v: var(--padding-xxl);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxl);
        --padding-h: var(--padding-xs);
    }
        
    &.--fullscreen {
        --padding-h: 0;
        --padding-v: 0;
    }
}

.block-slider-fullscreen {
    width: var(--width);
    user-select: none;
    margin: var(--padding-v) 0;

    &__slider {
        height: 100%;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        
        cursor: grab;    
        &:active {
            cursor: grabbing;
        }
    
        > .holder {
            order: 1;
            position: relative;
            height: 100%;
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
        }
    }
    
    &__item {
        position: relative;
        flex: 0 0 var(--width);
        width: var(--width);
        height: 100%;
        overflow: hidden;
    
        svg {
            height: 100%;
            width: auto;
        }

        figure {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }
    }

    &__controls {
        pointer-events: none;
        color: var(--primary-bg);

        @include font-sans-s(1);
        
        button {
            pointer-events: all;
            @include basic-a();
            @include font-sans-s(1);
            background-color: transparent;
            padding: 0;
        }
        
        .numbers {
            display: flex;

            @include z-index(1);
            @include font-serif-base(1);
            position: absolute;
            right: var(--padding-h);
            top: 50%;
            transform: translateY(-50%);
        }
    }

    @media (max-width: $smartphone) {
        > div {
            height: 100%;
            width: auto;
            overflow: hidden;
        }

        .holder {
            -webkit-overflow-scrolling: touch;
            overflow: hidden;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            gap: var(--padding-xs);
            height: 50vh;
            padding-right: var(--padding-xs);

            &::-webkit-scrollbar {
                display: none;
            }
        }
        
        &__item {
            width: auto;
            height: 100%;
            flex: 0 0 80vw;
            
            scroll-snap-align: start;
            
            &:first-child {
                padding-left: var(--padding-xs);
            }

            figure {
                height: 100%;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    
    @media (min-width: $smartphone) {
        height: 100vh;
        padding: 0 var(--padding-h);

        &__controls {
            button {
                @include z-index(1);
                position: absolute;
                top: 0;
                height: 100%;
                width: 10vw;

                > span {
                    display: none;
                }
                
                &[scroll-slider-prev] {
                    left: 0;
                }
                
                &[scroll-slider-next] {
                    right: 0;
                }
            }

            .numbers {
                padding-right: var(--padding-s);
            }
        }

        &__item {
            img {
                transform: scale(1.05);
            }
        }
    }
}
