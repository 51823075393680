.next-project {
    --margin-v: var(--padding-xxxxl);
    --padding-v: var(--padding-xxl);
    --padding-h: var(--padding-s);

    @media (max-width: $smartphone) {
        // --padding-v: var(--padding-l);
        --padding-h: var(--padding-xs);
    }
}

.next-project {
    padding: 0 var(--padding-h);
    margin: var(--margin-v) auto;
    height: calc(100vh - 2 * var(--padding-v));
    
    a {
        
        @include basic-a();
        color: var(--primary-color);
        
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        max-width: rem(1156);
        text-align: center;
        position: relative;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        
        figure {
            @include z-index(0);
            left: 0;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            
            video,
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .title {
            @include z-index(0);
            position: relative;
            overflow: hidden;
            height: .83em;
            @include font-serif-xl(.76);

            span {
                position: relative;
                display: block;

                > span {
                    display: block;
                    position: relative;
                    white-space: nowrap;
                    height: .83em;

                    &:nth-child(even) {
                        color: var(--yellow);
                    }
                }
            }
        }
    }
}

