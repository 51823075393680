.block-title-2-images-text-link {
    --img-width-1: #{rem(212px)};
    --img-width-2: #{rem(330px)};
    --title-width: #{rem(1200px)};
    --max-width: #{rem(448px)};
    --padding-v: var(--padding-xxxxl);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --img-width-1: 50%;
        --img-width-2: 100%;
        --padding-v: var(--padding-xxxl);
        --padding-h: var(--padding-xs);
    }

    
}

.block-title-2-images-text-link {
    color: var(--primary-color);
    padding: 0 var(--padding-h);
    margin: var(--padding-v) 0;
    
    .intro {    
        h2 {
            @include font-serif-base();
        }
    
        .label {
            @include font-sans-s();
        }
    }
    
    .img {
        &:first-child {
            width: var(--img-width-1);
        }

        &:last-child {
            width: var(--img-width-2);
        }
        
        figure {
            display: flex; 
            justify-content: center;
        }
        
        img,
        video {
            height: 120%;
            width: auto;
            left: auto;
        }
    }
    
    .text {
        @include font-sans-base();
        max-width: var(--max-width);

        p {
            &:not(:last-child) {
                margin-bottom: 1.5em;
            }
        }

        .link-underline {
            margin-top: var(--padding-s);
        }
    }

    .link-underline {
        @include font-sans-base();
    }

    @media (max-width: $smartphone) {
        .intro {
            margin-bottom: var(--padding-l);

            .label {
                margin-bottom: var(--padding-xxs);
            }

            .text {
                margin-top: var(--padding-s);
            }
        }
        
        .images .img:first-child {
            margin-bottom: var(--padding-xs);
        }
    }

    @media (min-width: $smartphone) {
        display: flex;
        justify-content: space-between;

        .intro {
            // display: flex;
            // flex-direction: column;
            // align-items: flex-start;
            padding-left: var(--padding-xxxl);

            .top {
                display: flex;
                gap: var(--padding-xxl);

                .label {
                    margin-bottom: 0;
                    flex: 0 1 rem(145);
                }
                
                h2 {
                    flex: 0 1 var(--title-width);
                }
            }

            .text {
                margin-top: var(--padding-m-2);
            }
        }
        
        .images {
            display: flex;
            align-items: flex-end;
            gap: var(--padding-s);
        }
    }
}
