@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    33.33% {
        opacity: 1;
    }
    66.66% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.billboard-project {
    --height: 100vh;
}

.billboard-project {
    overflow: hidden;

    .fold {
        @include z-index(1);
        position: relative;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: var(--padding-xs);
                
        > .title {
            @include font-serif-xxl(.71);
            color: var(--primary-header);
            overflow: hidden;
        }
    }

    .nota {
        @include font-sans-xs();
        color: var(--primary-header);
        position: absolute;
        bottom: 0;
        right: 0;
        padding: var(--padding-xs);
        max-width: rem(300);
    }
    
    .fold,
    > figure {
        height: var(--height);
    }

    > figure {
        @include z-index(0);
        left: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: 100%;
        
        img,
        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .info {
        --offset: calc(var(--padding-s) - .5rem);

        @include z-index(1);
        @include font-sans-s();
        width: 100%;
       // padding: var(--padding-xs);
        max-width: rem(330);
        color: var(--primary-header);
        border-radius: var(--radius-s);
        background-color: rgba(255,255,255,.0);
        backdrop-filter: blur(var(--blur));
        padding: .8rem .8rem 1.2rem;
        box-shadow: 0 .25rem 1rem rgba(0, 0, 0, 0.02);

        --border-size: .2px;
        --border-color: rgba(255, 255, 255, 0.05);
        border: var(--border-size) solid var(--border-color);

        h1 {
            small {
                @include font-sans-s();
            }

            .desc {
                display: block;
                @include font-sans-base();
                max-width: rem(330);
                margin-top: 0.2em;
                margin-bottom: 0.8em;
            }    
        }

        > a {
            @include basic-a();
            position: relative;
            overflow: hidden;
            
            figure {
                position: relative;
                @include aspect-ratio(330, 150);
            }
        
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                opacity: 0;
                animation: fadeInOut 9s infinite;
            }
        
            img:nth-child(1) {
                animation-delay: 0s;
            }
        
            img:nth-child(2) {
                animation-delay: 3s;
            }
        
            img:nth-child(3) {
                animation-delay: 6s;
            }
        }

        .img {
            width: 100%;
            margin-bottom: var(--padding-s);
            display: block;

            figure {
                display: flex; 
                justify-content: center;
            }
        }
        
        
        ul {
            font-variant-numeric: tabular-nums;
        }
    }

    @media (max-width: $smartphone) {
        height: var(--height);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .nota {
            padding: var(--padding-xs);
            position: relative;
            text-align: left;
            top: auto;
            bottom: auto;
        }

        .fold {
            height: auto;
            .info {
                max-width: rem(300);
            }
            .title {
                font-size: 30vw;
                margin-bottom: var(--padding-xs);
            }
        }
    }

    @media (min-width: $smartphone) {
        /*.info,*/
        .nota {
            padding: .7rem;
        }
        .fold {
            padding: var(--padding-s);
        }

        .info {
            position: absolute;
            height: auto;
            top: .7rem;
            right: .7rem;
        }
    }
}
