.block-text-steps {
    --padding-v: var(--padding-xxxxxl);
    --padding-h: var(--padding-s);

    --max-width: #{rem(450px)};
    --text-width: #{rem(330px)};
    --numbers-width: #{rem(720px)};

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxxl);
        --padding-h: var(--padding-xs);
    }
}

.block-text-steps {
    color: var(--primary-color);

    .holder {
        height: auto;
    }

    .text {
        max-width: var(--text-width);
        @include font-sans-base();

        h1,h2,h3 {
            @include font-sans-base();
        }

        > h3,
        h2 {
            @include font-sans-l();
            margin-bottom: .6em;

            strong,b {
                font-weight: inherit;
            }
        }
        
        > b, 
        > strong {
            font-weight: 700;
        }

        li > h3 {
            font-weight: 700;
            display: inline;
        }

        li > p {
            display: inline;
            margin: 0;
        }
        
        li,
        p {
            &:not(:last-child) {
                margin-bottom: 1em;
            }
        }
    }

    .slide {
        margin-left: auto;
        margin-right: 0;
    }

    .counter {
        @include font-serif-xxl();
        margin-bottom: var(--padding-s);
    }

    .numbers {
        @include font-serif-xxl();

        > div {
            display: flex;
            gap: var(--padding-xs);
        }
        
        .number {
            overflow: hidden;
            height: .83em;

            span {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                overflow: hidden;

                span {
                    display: block;
                    position: relative;
                    white-space: nowrap;
                    height: .83em;
                    padding: 0;
                }

                span:not(.--active) {
                    opacity: .25;
                }
            }
        }
    }
    
    @media (max-width: $smartphone) {
        margin: var(--padding-v) 0;

        .holder {
            padding: 0 var(--padding-h);
        }

        .slide:not(:last-child) {
            margin-bottom: var(--padding-l);
        }
    }

    @media (min-width: $smartphone) {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        gap: var(--padding-l);
        margin: var(--padding-v) 0;
    
        .slide {
            width: 100%;
            height: auto;
            gap: var(--padding-xxl);

            padding: 0 var(--padding-h);
            margin: var(--padding-xl) 0;
            opacity: .25;
            transition: opacity .2s ease-out;

            &.--active {
                opacity: 1;
            }

            &:first-child {
                margin-top: var(--padding-xxxl)
            }

            &:last-child {
                margin-bottom: 0;
            }

            .index {
                position: sticky;
            }
        }

        .numbers {
            // @include font-serif-xxl();
            flex: 0 1 rem(720);
            height: 100vh;
            
            // > div {
            //     display: flex;
            //     gap: var(--padding-xs);
            // }
        }
    }
}
