.block-2-images-text {
    --col-1: #{rem(330px)};
    --col-2: #{rem(566px)};

    --margin-v: var(--padding-xxxxl);
    --padding-v: var(--padding-xxl);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --col-1: 100%;
        --col-2: 70%;
        --margin-v: var(--padding-xxxl);
        --padding-v: 0;
        --padding-h: var(--padding-xs);
    }

    
}

.block-2-images-text {
    @include font-sans-base();
    padding: var(--padding-v) var(--padding-h);
    margin: var(--margin-v) 0;
    color: var(--primary-color);

    .left {
        > div {
            max-width: var(--col-1);
        }
    }

    .right {
        > div {
            max-width: var(--col-2);
        }
    }

    .text > * {
        p {
            &:not(:last-child) {
                margin-bottom: 1.5em;
            }
        }
    }

    .img {
        width: 100%;

        figure {
            display: flex; 
            justify-content: center;
        }

        img,
        video {
            height: 120%;
            width: auto;
            left: auto;
        }
    }

    @media (max-width: $smartphone) {
        .left {
            margin-bottom: var(--padding-m);

            .text {
                margin-top: var(--padding-xs);
            }
        }

        .right {
            > div {
                margin-left: calc(100% - var(--col-2));
            }
        }
    }

    @media (min-width: $smartphone) {
        display: flex;

        > * {
            flex: 0 1 50%;
        }

        .left {
            display: flex;
            justify-content: flex-end;
            padding-right: var(--padding-xxxl);
        }

        .right {
            margin-top: rem(-200);
        }

        .text {
            margin-top: var(--padding-m-2);
        }
    }
}
