.block-keywords {
    --max-width: #{rem(684px)};
    --padding-v: var(--padding-xxxxl);
    --padding-h: var(--padding-s);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxxl);
        --padding-h: var(--padding-xs);
    }
}

.block-keywords {
    color: var(--primary-bg);

    @include font-sans-s();
    margin: var(--padding-v) 0;
    
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    
    .text {
        @include z-index(1);
        position: relative;
        overflow: hidden;
        
        .keywords {
            --scale: .5;
            --width: calc(100% * var(--scale));
            --left: calc(50% - var(--width)/2);

            position: absolute;
            top: 0;
            left:var(--left);
            width: var(--width);
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 var(--padding-m);
        }

        .bg {
            background-color: rgba(0, 0, 0, .6);

            .img {
                top: 0;
                display: flex;
                justify-content: center;

                img {
                    position: absolute;
                    filter: blur(30px);
                }
            }
        }
    }
    
    .img {
        @include z-index(0);
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        transform: scale(1.2);

        img {
            height: 120%;
            width: auto;
            left: auto;
        }
    }

    > .img {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    /*@media (max-width: $smartphone) {
        .text {
            width: 100%;

            .keywords {
                position: relative;
                padding: var(--padding-v) var(--padding-h);
            }
        }
    }*/
    
   // @media (min-width: $smartphone) {
        padding: var(--padding-v) var(--padding-h);

        .text {
            width: 51%;

            @media (max-width: $smartphone) {
                width: 75%;
            }
            
            .bg {
                @include aspect-ratio(16, 9);
            }

            .text {
                .keywords {
                    padding: var(--padding-h);
                 
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    //}
}
