.widget-contact {
    --padding-v: var(--padding-xxxxl);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxxxl);
        --padding-h: var(--padding-xs);
    }
}

.widget-contact {
    padding: var(--padding-v) var(--padding-h);
    
    .intro {
        margin-bottom: var(--padding-l);
        
        @include font-sans-m();
        text-align: center;
        
        h1 {
            @include font-serif-l();
            max-width: rem(685);
            margin: 0 auto var(--padding-l);
        }
        
        .subtitle {
            max-width: rem(920);
            margin: 0 auto;
        }
    }

    &.--thanks {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .intro {
            margin-bottom: 0;
        }
    }

    form {
        order: 2;
    }

    .img {
        position: relative;
        overflow: hidden;
        flex-grow: 1;
        order: 1;
        
        figure {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            
            img {
                position: absolute;
                height: 120%;
                width: auto;
                top: 0;
                object-fit: cover;
                object-position: center;
                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
                user-drag: none;
            }
        }
    }
    
    @media (min-width: $smartphone) {
        .intro {
            margin-bottom: var(--padding-xxxl);

            h1 {
                margin-bottom: var(--padding-s);
            }
        }

        .contact {
            display: flex;
            gap: var(--padding-s);

            form {
                flex: 0 0 rem(650);
                padding-right: var(--padding-xxl);
            }
        }
    }
}
