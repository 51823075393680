.not-found {
    --padding-v: var(--padding-xxxxl);
    --padding-h: var(--padding-s);
    
    --width: #{rem(340px)};
    --color: var(--primary-color);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxxl);
        --padding-h: var(--padding-xs);
    }
}

.not-found {
    padding: 0 var(--padding-h);
    margin: var(--padding-v) 0;

    display: flex;
    align-items: center;
    justify-content: center;

    > div {
        text-align: center;
    }

    @include font-sans-l();

    .simbolo {
        width: var(--width);
        margin-bottom: var(--padding-xl);

        svg {
            width: 100%;
            height: auto;
            fill: var(--color);
            transition: fill .3s ease-out;
        }
    }

    // @media (max-width: $smartphone) {
    //     padding: 35vh var(--padding-h) 5vh;

    //     .filters {
    //         margin-top: var(--padding-xxxl);
    //     }
    // }
}
