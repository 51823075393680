.widget-project-list {
    --color: var(--primary-color);
    --bg: var(--primary-bg);
    
    @media (min-width: $smartphone) {
        --width-filters: 20vw;
        --height-filters: 100vh;
        --cols: 3;
        --padding: var(--padding-s);    
        --padding-projects: var(--padding-s) var(--padding-s) var(--padding-xxl) var(--padding-s);    
        --gap-grid: var(--padding-xs);
    }

    @media (max-width: $smartphone) {
        --width-filters: 100%;
        --height-filters: auto;
        --cols: 2;
        --padding: var(--padding-xs); 
        --padding-projects: var(--padding-xs) var(--padding-xs) var(--padding-xxl);      
        --gap-grid: var(--padding-xxs);
    }
}

.widget-project-list {

    @media (min-width: $smartphone) {
        display: grid;
        grid-template-columns: var(--width-filters) 1fr;
    }

    @media (max-width: $smartphone) {
        padding-top: var(--padding-xxl);
    }

    align-items: flex-start;

    .bg {
        position: absolute;
        top:-25vh;
        left:-25%;
        width: 150%;
        height: 150vh;
        pointer-events: none;

        img {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            filter: blur(50px);
        }
    }

    .list {
        counter-reset: cont;
        display: grid;
        grid-template-columns: repeat(var(--cols), 1fr);
        align-items: flex-start;
        justify-content: flex-start;
        gap: var(--gap-grid);
        padding: var(--padding-projects);
        height: auto;
    
        li {
            counter-increment: cont;
    
            a {
                --blur: 0;
                --scale: 1;
                --time-scale: .4s;
    
                @include basic-a();
                @include font-sans-base();
                display: block;
                color: var(--color);

                span {
                    display: block;
                    position: relative;
                    overflow: clip;
                    background-color: var(--bg);
                }
                
                figure {
                    @include aspect-ratio(1, .7);
                    filter: blur(var(--blur));
                    transition: filter var(--time-scale) var(--ease-in-out-quad);
    
                    img, video {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        transform: scale3d(var(--scale), var(--scale), var(--scale));
                        transition: transform var(--time-scale) var(--ease-in-out-quad);
                    }
                }
    
                &:hover {
                    --blur: 50px;
                    --scale: 2;
                    --time-scale: .8s;
                }
            }
    
            figcaption {
                position: relative;
                @include font-sans-s();
                padding: .4em .1em 0;
                display: flex;
                justify-content: space-between;

                span {
                    background-color: transparent;
                    display: inline-block;
                }

                h2 {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    @include font-sans-s();
                    font-size: 1em;
                    margin: 0;

                    &::after {
                        content: counter(cont, decimal-leading-zero) ' ';
                    }
                }
            }
        }
    }    
}

.widget-project-list__filters {
    width: var(--width-filters);
    height: var(--height-filters);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: var(--padding);
    pointer-events: none;
    font-variant-numeric: tabular-nums;
    gap:1em;

    @media (max-width: $smartphone) {
        align-items: flex-start;
    }

    a {
        color: var(--color);
        pointer-events: all;
    }

    > li {
        @include font-sans-s();
        position: relative;
        display: block;
        height: auto;

        > ul {
            padding-top: 1.2em;
            position: absolute;
            top: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            white-space: nowrap;
            gap: 0;

            @media (max-width: $smartphone) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 1em;
                position: relative;
            }

            li {
                margin-bottom: .5em;
            }
        }
    }
}