.block-2-images-text-project {
    --max-width: #{rem(450px)};
    --padding-v: var(--padding-xxxl);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xl);
        --padding-h: var(--padding-xs);
    }
}

.block-2-images-text-project {
    @include font-sans-base();
    padding: 0 var(--padding-h);
    margin: var(--padding-v) 0;
    color: var(--primary-color);

    .text > * {
        margin: 0 auto;
        max-width: var(--max-width);

        p {
            &:not(:last-child) {
                margin-bottom: 1.5em;
            }
        }
    }

    .label {
        @include font-sans-s();
        margin-top: var(--padding-xs);
        max-width: 70%;
    }

    .img {
        width: 100%;

        figure {
            display: flex; 
            justify-content: center;
        }

        video,
        img {
            height: 100%;
            width: auto;
            max-width: 100%;
            left: auto;
            object-fit: cover;
        }
    }

    @media (max-width: $smartphone) {
        .text {
            margin: var(--padding-l) 0;
        }
    }

    @media (min-width: $smartphone) {
        display: flex;
        gap: var(--padding-s);
        justify-content: space-between;

        > * {
            flex: 0 1 50%;
        }

        .label {
            margin-top: var(--padding-s);
        }

        .left {
            //margin-top: var(--padding-xxl);
        }

        .text {
            margin-bottom: var(--padding-xxxxl);
        }

        .img {
            img {
                height: 120%;
            }
        }
    }
}
