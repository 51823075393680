//↗ → ← ↖

@mixin link-circle() {
    --pos: 0;
    --scale: 0;
    --pos-hover: 1.5em;
    --ease: var(--ease-out-quad);
    --time: .4s;
    --size-icon: max(0.20rem, 4px);
    --height: #{rem(9)};
    --pos-icon: calc(var(--height) / 2 - var(--size-icon) / 2);

    @include basic-a();
    position: relative;
    padding: 0 var(--pos-hover) 0 0;
    background: transparent;
    
    &:before {
        @include pseudo-element-absolute('');
        top: var(--pos-icon);
        left: -.7em;
        width: var(--size-icon);
        height: var(--size-icon);
        border-radius: 50%;
        background-color: currentColor;
        
        transform: scale(var(--scale));
        transition: transform var(--time) var(--ease);
    }

    > span {
        display: block;
        //transform: translateX(var(--pos));
        //transition: transform var(--time) var(--ease);
    }

    &:hover {
    --scale: 1;
     --pos: var(--pos-hover);
    }

    &[aria-current="page"],
    &.--active,
    &.__link-active {
        --scale: 1;
        --pos: var(--pos-hover);
        pointer-events: none;
    }

    /// TOUCH
    @include isTouch() {
        overflow: visible;

        &:before {
            opacity: 0;
            transform: translateX(0);
        }

        > span {
            transform: translateX(0);
        }

        &:hover {
            &:before {
            opacity: 1;
            }
        }
    }
}

.link-circle {
    @include link-circle();
}
