.block-text-2-images-columns {
    --padding-v: var(--padding-xxxxl);
    --padding-h: var(--padding-s);

    --max-width: #{rem(1040px)};
    --text-width: #{rem(565px)};
    --margin: var(--padding-l) 0 var(--padding-l) auto;

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxxl);
        --padding-h: var(--padding-xs);
        --margin: var(--padding-m) 0;
    }

    // display: none !important;
}

.block-text-2-images-columns {
    .wrapper {
        margin: var(--margin);
        padding: 0 var(--padding-h);
        color: var(--primary-color);
    }
    
    .text {
        max-width: var(--text-width);
        width: 100%;
        @include font-sans-s();

        h1,h2,
        strong {
            font-weight: 500;
            @include font-sans-l();
            margin-bottom: .75em;
        }

        p {
            &:not(:last-child) {
                margin-bottom: 1.5em;
            }
        }
    }

    .img {
        width: 100%;
        margin-top: var(--padding-xs);

        figure {
            display: flex; 
            justify-content: center;
        }

        img {
            height: 120%;
            width: auto;
            left: auto;
        }
    }

    @media (max-width: $smartphone) {
        

        .images {
            width: 100%;

            // .img:first-child {
            //     margin-bottom: var(--padding-xs);
            // }
        }
    }

    @media (min-width: $smartphone) {
        .images {
            display: flex;
            gap: var(--padding-s);

            .img {
                margin-top: var(--padding-m);
            }
        }

        .wrapper {
            width: var(--max-width);
        }
    }
}
