.block-text-steps-title-image {
    --padding-v-top: var(--padding-xxxl);
    --padding-v-bottom: var(--padding-xxxxxl);
    --padding-h: var(--padding-s);

    --max-width: #{rem(565px)};
    --text-width: #{rem(450px)};
    --numbers-width: #{rem(720px)};

    @media (max-width: $smartphone) {
        --padding-v-bottom: var(--padding-xxxl);
        --padding-h: var(--padding-xs);
    }

    
}

.block-text-steps-title-image {
    color: var(--primary-color);

    h2 {
        @include font-serif-xl();
        text-align: center;
        margin-bottom: var(--padding-l);
    }

    .holder {
        height: auto;
    }

    .counter {
        @include font-sans-s();
    }

    .slide {
        max-width: var(--max-width);
    }

    .text {
        max-width: var(--text-width);
        @include font-sans-base();

        h1, h2, h3 {
            margin: 0;
            @include font-sans-bold();
            font-weight: 700;
            font-size: inherit;
        }

        p {
            &:not(:last-child) {
                margin-bottom: 1.5em;
            }
        }
    }
     
    .slide {
        width: 100%;
        height: auto;

        padding: 0 var(--padding-h);
        margin: var(--padding-m) 0;
        transition: opacity .2s ease-out;

        display: flex;
        gap: var(--padding-s);

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .counter {
            flex: 0 1 rem(45)
        }
    }

    .img-wrapper {
        overflow: hidden;

        .box {
            overflow: hidden;
            position: relative;
        }

        .bg {
            position: absolute;
            width: 100%;
            height: 100%;

            img {
                filter: blur(30px);
            }
        }

        .img {
            @include z-index(0);
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            transform: scale(1.2);

            img {
                height: 120%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    @media (max-width: $smartphone) {
         .img-wrapper {
            height: 50vh;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-top: var(--padding-m);

            .img {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .box {
                height: 50%;
                width: 50%;
            }
        }
    }

    @media (min-width: $smartphone) {
        margin: 0 0 var(--padding-v-bottom);
        
        > [data-scroll-sticky] {
            @include z-index(1);
            position: relative;
            padding-top: var(--padding-l);

            h2 {
                margin-bottom: var(--padding-xxxxl);
            }
        }
        
        > .wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            gap: var(--padding-l);

            .holder {
                padding: 50vh 0 100vh;
            }
        
            .slide {
                margin: var(--padding-xxl) 0;
                opacity: .25;
    
                &.--active {
                    opacity: 1;
                }

                .counter {
                    flex: 0 0 rem(95)
                }
            }
    
            .img-wrapper {
                flex: 0 1 rem(708);
                height: 100vh;
                display: flex;
                align-items: center;
                justify-content: center;

                .box {
                    height: 45%;
                    width: 45%;
                }
            }
        }
    }
}
