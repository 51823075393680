.block-title {
    --padding-v: 0;
    --padding-h: var(--padding-s);
    --margin: var(--padding-xxxl) 0 var(--padding-xl);
    --max-width: #{rem(800)};
    
    @media (max-width: $smartphone) {
        --padding-v: 0;
        --padding-h: var(--padding-xs);
    }
}

.block-title {
    padding: var(--padding-v) var(--padding-h);
    margin: var(--margin);
    display: flex;
    justify-content: center;

    .title {
        position: relative;
        max-width: var(--max-width);

        > .title {
            @include font-serif-l();
            overflow: hidden;
            text-align: center;
        }
        
        .label {
            @include font-sans-s();
            position: absolute;
            bottom: calc(100% + var(--padding-xs));
            left: .4em;
        }
    }

    // @media (min-width: $smartphone) {
    // }
}
