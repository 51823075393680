.block-billboard-index {
    --height-vh: 100vh;
    --height-svh: 100svh;
    --height: var(--height-svh, var(--height-vh));

    .--safari & {
        --height: var(--height-vh);
    }
}

.element {
    height: var(--height);
}


.block-billboard-index {
    overflow: hidden;

    .fold {
        @include z-index(1);
        position: relative;
        align-items: center;
        display: flex;
        justify-content: center;
        padding: var(--padding-xs);
        
        h1 {
            @media (min-width: $smartphone) {
                @include font-serif-xl(.71);
            } 
            @media (max-width: $smartphone) {
                @include font-serif-xl(1);
            } 
            color: var(--primary-color);
            text-align: center;
            overflow: hidden;
        }
    }
    
    .fold,
    .bg,
    figure {
        height: var(--height);
    }

    .bg {
        @include z-index(0);
        left: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: 100%;

        > div {
            overflow: hidden;
        }

        figure {
            img,
            video {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .left {
            img {
                filter: blur(50px);
            }
            &::after {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255,255,255,0.1);
            }
        }

        .right {
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            height: 100%;
        }
    }

    .img {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        > div {
            @include aspect-ratio(330, 149);
        }

        > * {
            pointer-events: none;
        }

        img,
        video {
            position: absolute;
            top: 0;
            bottom: 0;
            height: auto;
            width: 100%;
            object-fit: cover;
        }
    }

    @media (min-width: $smartphone) {
        .bg {
            display: flex;
        }

        .info,
        .fold {
            padding: var(--padding-s);
        }

        .info {
            position: absolute;
            height: auto;
            bottom: 0;
            left: 0;
        }
        
        .img {
            bottom: var(--padding-s);
            position: absolute;
            width: rem(330);
        }
    }
}

.block-billboard-index__info {
    @include font-sans-s();
    width: 100%;
    padding: var(--padding-xs);
    max-width: rem(300);

    position: absolute;
    bottom: 0;
    left: 0;
    @include z-index(3);

    @media (min-width: $smartphone) {
        padding: var(--padding-s);
        max-width: rem(300);
    }

    @media (max-width: $smartphone) {
        max-width: 50vw;
    }

    ul {
        font-variant-numeric: tabular-nums;
    }
    
    .desc {
        @include font-sans-m();
        max-width: rem(300);
        margin-top: .2em;
        margin-bottom: .8em;
    }
}
