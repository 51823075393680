.block-slider-horizontals-scroll {
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxxl);
    }
}

.block-slider-horizontals-scroll {
    position: relative;
    width: 100vw;
    overflow: hidden;
    
    .holder {
        display: flex;
        height: 100%;
        width: auto;
    }

    .slide {
        overflow: hidden;

        img {
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }
    }
    
    @media (max-width: $smartphone) {
        margin: var(--padding-v) 0;

        > div {
            height: 100%;
            width: auto;
            overflow: hidden;
        }

        .holder {
            -webkit-overflow-scrolling: touch;
            overflow: hidden;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            gap: var(--padding-xs);
            height: 50vh;
            padding-right: var(--padding-xs);

            &::-webkit-scrollbar {
                display: none;
            }

        }
        
        .slide {
            width: auto;
            height: 100%;
            flex: 0 0 80vw;
            
            scroll-snap-align: start;
            
            &:first-child {
                padding-left: var(--padding-xs);
            }

            figure {
                height: 100%;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    
    @media (min-width: $smartphone) {
        height: var(--height);
        
        > div {
            height: 100vh;
            position: relative;
            width: 100vw;
            overflow: hidden;
        }
            
        .slide {
            width: auto;
            height: 100vh;
            
            figure {
                height: 100vh;
                width: calc(100vh * (1 / var(--aspect)));
            }

            img {
                height: 100%;
                width: 100%;
                position: absolute;
            }
        }
        
        .holder {
            left: 0;
            position: absolute;
            top: 0;
        }
    }
}