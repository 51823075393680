.block-title-text-image-centered {
    --max-width: #{rem(1200px)};
    --max-width-text: #{rem(684px)};
    --max-width-image: #{rem(450)};
    --padding-h: var(--padding-s);
    --padding-v: var(--padding-xxxxl);

    @media (max-width: $smartphone) {
        --max-width-image: #{rem(100)};
        
        --padding-v: var(--padding-xxl);
        --padding-h: var(--padding-xs);
        text-align: center;
    }
}

.block-title-text-image-centered {
    color: var(--primary-color);
    
    @include font-sans-base();
    padding: 0 var(--padding-h);
    margin: var(--padding-v) 0;
            
    .text {
        margin: 0 auto;
        text-align: center;
        max-width: var(--max-width-text);

        p {
            &:not(:last-child) {
                margin-bottom: 1.5em;
            }
        }
    }
    
    .top {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: $smartphone) {
            flex-direction: column;
        }

        h2 {
            @include font-serif-l();
            overflow: hidden;

            
        }
        
        .img {
            max-width: var(--max-width-image);
            width: 100%;
            margin: 0 auto;

            figure {
                display: flex; 
                justify-content: center;
            }

            img,
            video {
                height: 100%;
                width: auto;
                left: auto;
            }
        }
        
        .title {
            position: absolute;
            max-width: var(--max-width);
            padding: 0 var(--padding-xs);

            @media (max-width: $smartphone) {
                margin-top: 1rem;
                position: relative;
            }

        }
    }

    @media (max-width: $smartphone) {
        .top {
            margin-bottom: var(--padding-xs);

            .title {
                .label {
                    margin-bottom: var(--padding-xs);
                }
            }
        }
    }

    @media (min-width: $smartphone) {
        .top {
            margin-bottom: var(--padding-m);

            .img {
                img,
                video {
                    height: 120%;
                }
            }
            
            .title {
                .label {
                    @include font-sans-s();
                    position: absolute;
                    bottom: calc(100% + var(--padding-xs));
                    left: .4em;
                }
            }
        }
    }
}
