:root {
    --white: #D8D9E3;
    --black: #1A1A1E;
    --grey: #A2ADB4;
    --red: #682A15;
    --brown: #664B33;
    --yellow: #ae915a;//#756649;
    --green: #4C6157;

    --primary: var(--black);
    --secondary: var(--white);

    --assertive: #ff00ff;
    --focus: #ff00ff;
    --color-error: #ff0000;
    --color-sucess: #4dc247;
}
