#Header,
#HeaderLogo {
    --color: var(--primary-header);
    --logo-size: #{rem(180)};
    --gap-links: .6em;
    
    @media (min-width: $smartphone) {
        --logo-size: #{rem(180)};
        --padding: var(--padding-s);
    }
    
    @media (max-width: $smartphone) {
        --gap-links: .8em;
        --padding: var(--padding-xs);
        --logo-size: #{rem(180)};
    }

    &.--force-white {
        --color: var(--white) !important;
    }
}

.--mode-logo {
    #Header {
        > .logo {
            opacity: 0;
        }
    }
    #HeaderLogo {
        display: block;
    }
}

#HeaderLogo {
    display: none;
    pointer-events: none;
    position: fixed;
    @include z-index($z-index-header-logo);
    width: calc(100% - var(--padding) * 2);
    left: var(--padding);
    top: calc(50% - 9vw);
    top: calc(50svh - 9vw);
    transform-origin: 0 0;

    svg {
        display: block;
        width: 100%;
        height: auto;
        fill: var(--color);
        transform-origin: 50% 50%;
        transform: scale3d(1.5, 1.5, 1);
    }
}

#Header {
    position: fixed;
    @include z-index($z-index-header);
    width: auto;
    height: auto;
    padding: var(--padding) var(--padding) 0;

    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .Header__toggle.button-round.--outline {
        --color: var(--primary-header);
    }

    @media (min-width: $smartphone) {
        transform: translate3d(0, var(--y-header), 10px);
        flex-direction: column;

        .Header__toggle {
            display: none;
        }

    }

    @media (max-width: $smartphone) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

    }
    
    > .logo {
        position: relative;
        display: block;
        width: var(--logo-size);
        height: auto;

        @media (min-width: $smartphone) {
            order: 2;
        }

        @media (max-width: $smartphone) {
            order: 1;
        }

        svg:nth-child(1) {
            display: block;
            width: 100%;
            height: auto;
            fill: var(--color);
            opacity: 1;
            transition: opacity 0.1s ease-out, transform .1s ease-out;
            transform-origin: 0 0;
        }

        svg:nth-child(2) {
            position: absolute;
            top: 0;
            width: auto;
            height: 100%;
            fill: var(--color);
            opacity: 0;
            transition: opacity 0.1s ease-out, transform .1s ease-out;
            transform: scale3d(2, 1, 1);
            transform-origin: 0 0;

            @media (min-width: $smartphone) {
                left: rem(-8);
            }
        }
    }

    &.--symbol > .logo {
        svg:nth-child(1) {
           opacity: 0;
           transform: scale3d(.2, 1, 1);
           transition: opacity 0.1s ease-out, transform 0.2s ease-out;
        }

        svg:nth-child(2) {
            opacity: 1;
            transition: opacity 0.1s ease-out, transform 0.1s ease-out;
            transform: scale3d(1, 1, 1);
        }
    }

    > nav {
       
        display: flex;
        
        align-items: flex-start;
        padding: var(--padding) 0 0;
        margin-bottom: var(--padding);
            
        padding: 0;

        @media (min-width: $smartphone) {
            order: 1;
            flex-direction: column;

            .Header__toggle {
                display: none;
            }
        }

        @media (max-width: $smartphone) {
            order: 2;
            flex-direction: row;
            margin: 0;
            
            .Header__toggle {
                display: block;
            }
        }

        button, a {
            @include font-sans-s(1);
            display: inline-block;
            width: auto;
            margin-bottom: var(--gap-links);
            color: var(--color);
        }

        button.link-circle {
            margin-bottom:0;
        }

        @media (max-width: $smartphone) {
            button, a {
                //@include font-sans-m(1);
                display: none;
            }
        }
    }

    &.--blur-mode {
        > nav {
            border-radius: var(--radius-s);
            background-color: rgba(255,255,255,.1);
            backdrop-filter: blur(var(--blur));
            padding: .5em 1em .44em;
            box-shadow: 0 .25rem 1rem rgba(0, 0, 0, 0.1);
    
            --border-size: .2px;
            --border-color: rgba(255, 255, 255, 0.05);
            border: var(--border-size) solid var(--border-color);

            button, a {
                color: var(--white);
            }
        }
    }
}
