.block-media-fullscreen {
    --padding-v: var(--padding-xxxxl);
    --max-width: #{rem(916px)};

    &.--fullscreen {
        --max-width: auto;
    }

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxxl);
    }

    
}

.block-media-fullscreen {
    margin: var(--padding-v) 0;

     > * {
        margin: 0 auto;
        max-width: var(--max-width);
        width: 100%;
    }

    .img {
        width: 100%;

        figure {
            display: flex; 
            justify-content: center;
        }

        img {
            height: 120%;
            width: auto;
            left: auto;
        }
    }
}
