.block-text-image-columns {
    --padding-v: var(--padding-xxxxl);
    --padding-h: var(--padding-s);

    --max-width: #{rem(1040px)};
    --text-width: #{rem(565px)};
    --margin: var(--padding-l) 0 var(--padding-l) auto;

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxxl);
        --padding-h: var(--padding-xs);
        --margin: var(--padding-m) 0 var(--padding-m) auto;
    }

    // display: none !important;
}

.block-text-image-columns {
    .wrapper {
        padding: 0 var(--padding-h);
        color: var(--primary-color);

        display: flex;

        &.--center { justify-content: center; }
        &.--right { justify-content: flex-end; }
 
        > * {
            max-width: var(--text-width);
            width: 100%;
        }
    }



    .text {
        @include font-sans-s();

        h1,h2,h3 {
            @include font-sans-s();
        }

        h1,h2,
        strong {
            font-weight: 500;
            @include font-sans-l();
            margin-bottom: .75em;
        }

        p {
            &:not(:last-child) {
                margin-bottom: 1.5em;
            }
        }
    }

    .img {
        margin-top: var(--padding-xs);
    }

    @media (max-width: $smartphone) {
        margin: var(--margin);
    }

    @media (min-width: $smartphone) {
        .wrapper {
            margin: var(--margin);
            width: var(--max-width);
        }

        .img {
            width: 100%;
            margin-top: var(--padding-m);

            figure {
                display: flex; 
                justify-content: center;
            }

            img {
                height: 120%;
                width: auto;
                left: auto;
            }
        }
    }
}
