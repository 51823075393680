.button-round {
    --scale: 1;
    --color: var(--primary-bg);
    --bg: var(--primary-color);
    --border-size: 0px;
    --border-color: var(--color);
    --border-radius: 1em;
    --height: #{rem(30)};
    --icon-size: #{rem(15)};
    --icon-y: calc((var(--height) - var(--icon-size)) / 2);

    &.--invert {
        --color: var(--primary-color);
        --bg: var(--primary-bg);
    }

    &.--outline {
        --color: var(--primary-color);
        --bg: transparent;
        --border-size: 1px;
        --border-color: var(--color);
    }

    &.--secondary {
        --color: var(--secondary-color);
        --bg: var(--secondary-bg);
    }

    &.--blur {
        --color: #000;
        --bg: rgba(255, 255, 255, 0.3);
        --border-size: .2px;
        --border-color: rgba(255, 255, 255, 0.01);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(var(--blur));
    }

    --font-size: var(--font-size-s);
    --padding: 0 1em 0 2.6em
}

.button-round {
    --x:-200%;
    --deco-scale:2;
    --icon-scale:1;

    @include font-sans(1);
    @include basic-a();

    position: relative;
    overflow: clip;    

    > span {
        @include z-index(2);
        position: relative;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        text-align: left;
        /*transition: 
            transform 0.4s var(--ease-in-power1-out-power3),
            width 0.4s var(--ease-in-power1-out-power3);*/
                        
        > span {
            position: relative;
            display: inline-flex;
            width: auto;
            align-items: center;
            padding-top: 0;
            min-width: 20px;
            height: var(--height);
            white-space: nowrap;
            text-align: left;
        }
    }

    &.--has-icons svg {
        opacity: 0;
    }

    > svg {
        @include z-index(2);
        position: absolute;
        width: var(--icon-size);
        height: var(--icon-size);
        top: var(--icon-y);
        left: var(--icon-y);
        fill: var(--color);
        transform: scale3d(var(--icon-scale),var(--icon-scale),1);
        transform-origin: center center;
        transition: transform 0.8s var(--ease-in-power1-out-power3);
    }


    position: relative;
    padding: var(--padding);

    width: auto;
    height: var(--height);
    color: var(--color);
    background: var(--bg);
    border: var(--border-size) solid var(--border-color);
    border-radius: var(--height);

    align-items: center;
    appearance: none;

    cursor: pointer;
    color: var(--color);
    display: block;
    font-size: var(--font-size);
    
    text-align: center;
    vertical-align: top;

    * { pointer-events: none; }
    span { display: block; }

    @include isCursor() {
        &:hover {
            --icon-scale: 1.25;
            /*--x:0;
            --deco-scale:1;*/
        }
    }

    &--alt {
        --bg: var(--white);
        --color: var(--black);
    }
}
