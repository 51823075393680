.block-highlight-projects-mobile {
    --padding-v: var(--padding-xxxl);
    --padding-h: var(--padding-xs);
}

.block-highlight-projects-mobile {
    margin: var(--padding-v) 0;

    > div {
        height: 100%;
        width: auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    .content {
        -webkit-overflow-scrolling: touch;
        overflow: hidden;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        gap: var(--padding-xs);
        padding-right: var(--padding-xs);
        display: flex;
        flex-wrap: nowrap;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    
    .project {
        width: 80vw;
        height: 100%;
        flex: 0 0 80vw;
        @include basic-a();
        display: block;
        scroll-snap-align: start;
        
        &:first-child {
            padding-left: var(--padding-xs);
        }

        .title {
            display: inline-block;
            overflow: hidden;
            width: 100%;
            height: 1em;
            overflow: hidden;
            @include font-serif-xl(1);
            color: var(--yellow);
            text-align: center;
        }

        figure {
            width: 100%;
            height: 40vh;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    @media (min-width: $smartphone) {
        padding: 0 var(--padding-h);
    }
}
