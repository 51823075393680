#Sidemenu-fake,
#Sidemenu {
    --bg: var(--secondary-bg);
    --color: var(--secondary-color);
    --padding: var(--padding-s);
    --padding-card: #{rem(6)};
    --contact-margin-left: #{rem(118)};
    --contact-label-width: #{rem(80)};
    --symbol-margin: #{rem(103)};
    --symbol-width: #{rem(94)};

    --height-vh: 100vh;
    --height-svh: 100dvh;
    --height: var(--height-svh, var(--height-vh));

    .--safari & {
        --height: var(--height-vh);
    }
    
    @media (max-width: $smartphone) {
        --padding: var(--padding-xs);
        --symbol-margin: #{rem(15)};
        --symbol-width: #{rem(26)};
    }
}

#Sidemenu-fake {
    position: fixed;
    @include z-index($z-index-sidemenu);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: var(--height);
    opacity: 0;
    pointer-events: none;
    background: rgba(255, 255, 255, 0.0);
    backdrop-filter: blur(3rem);
}

#Sidemenu {
    position: fixed;
    @include z-index($z-index-sidemenu);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: var(--height);
    background-color: transparent;
    color: var(--color);
    font-size: var(--font-size);

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    > .content {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        transform-origin: 50% 50%;

        > div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            display: flex;
        }
    }

    > .bg,
    > .fake-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: 0 0;
    }

    > .bg {
        background-color: var(--bg);
    }

    > .fake-bg {
        background-color: var(--color);
        
    }

    @media (max-width: $smartphone) {
        > .content {
            > div {
                gap: var(--padding-xs);
            }
        }

        .sidemenu__slider {
            pointer-events: none;
            flex: 0 0 33.3333%;
            width: 33.3333%;
            position: absolute;
            z-index: 2;
            transform: translateZ(2px);

            li {
                padding: 0;
                bottom: 0;
            }

            button,
            a {
                @include basic-a();
                pointer-events: all;
            }
        }

        .sidemenu__nav {
            padding: var(--padding);
            flex: 0 0 40%;
            padding-bottom: rem(120);
            pointer-events: none;

            button,
            a {
                pointer-events: all;
            }
        }
       
        .sidemenu__contact {
            padding: var(--padding);
            flex: 1 0 50%;
            padding-bottom: rem(120);

            pointer-events: none;

            button,
            a {
                pointer-events: all;
            }
        }
        
        .sidemenu__nav {
            padding-right: 0;
            
            .list {
                padding-bottom: rem(120);
            }

            // > header {
                // position: relative;
            //     top: rem(120);
            // }
        }

        .sidemenu__contact {
            justify-content: space-between;
            padding-left: 0;
            align-items: flex-end;

            .contact {
                li {
                    > div {
                        margin-bottom: 1em;
                    }

                    .title {
                        margin-bottom: .5em;
                    }
                }
            }

            @media (max-width: $smartphone) {
                padding-right: var(--padding);
            }   
        }
    }

    @media (min-width: $smartphone) {
        > .content {
            > div {
                // position: absolute;
                // top: 0;
                // left: 0;
                // width: 100%;
                // height: 100vh;
                display: flex;

                > div {
                    flex: 0 0 33.3333%;
                }
            }
        }
    }

    @media (min-width: $smartphone) {
        .sidemenu__contact .sidemenu__toggle {
            display: none;
        }
    }
}

.sidemenu__nav {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: var(--padding) 0 var(--padding) var(--padding);
    height: 100%;

    > header {
        > .fake {
            position: absolute;
            top: 0;
            left:0;
            opacity: 0;
            pointer-events: none;
        }

        position: absolute;
        top: 0;
        left: 0;
        padding: var(--padding);
    }
    
    > .list {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        height: 100%;
        padding: var(--padding) 0 var(--padding) var(--padding);
        list-style: none;

        display: flex;
        flex-direction: column;
        justify-content: center;

        pointer-events: none;
        
        li {
            display: block;
            @include font-serif-m();
            text-transform: uppercase;

            a {
                @include basic-a();
                display: inline-block;
                pointer-events: all;
                color: var(--color);
                overflow: hidden;
                height: .83em;
                
                > span {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    overflow: hidden;
                        
                    > span {
                        display: block;
                        position: relative;
                        white-space: nowrap;
                        height: .83em;
                        padding: 0;
                        
                        &:not(:first-child) {
                            opacity: 0;
                        }

                        &:nth-child(even) {
                            color: var(--yellow);
                        }
                    }
                }
            }
        }
    }

    > .copyright {
        @include font-sans-s(1);
    }

    @media (max-width: $smartphone) {
        
        .sidemenu__toggle {
            display: none;
        }
    }
    
}

.sidemenu__slider {
    position: relative;
    height: 100%;
    padding: 0 var(--padding);
    transform-origin: 50% 50%;

    ul {
        @media (min-width: $smartphone) {
        position: relative;
        }
    }

    li {
        width: 100%;
        padding: var(--padding-card) 0;

        figure {
            @include aspect-ratio(448,333);
            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}

.sidemenu__contact {
    padding: var(--padding) var(--padding) var(--padding) var(--contact-margin-left);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    .contact {
        li {    
           >.title {
                @include font-sans-s(1);
                flex: 0 0 var(--contact-label-width);
                padding-top: .2em;
                width: var(--contact-label-width);       
            }

            > .content {
                @include font-sans-base();

                a {
                    @include basic-a();
                    display: block;
                    color: inherit;
                }

                .map-link {
                    @include font-sans-s();
                    display: inline-block;
                    margin-top: var(--padding-xs);
                }
            }

            &.hours {
                margin: var(--padding) 0;
            }

            @media (min-width: $smartphone) {
                display: flex;
            }
        }
    }

    .simbolo {
        margin: var(--symbol-margin) 0;
        width: var(--symbol-width);

        figure {
            width: 100%;

            svg {
                width: 100%;
                height: auto;
                fill: var(--color);
            }
        }
    }

    .legales {
        @include font-sans-s();
        a {
            @include basic-a();
            color: inherit;
        }
    }
}