.block-image-text {
    --max-width: #{rem(450px)};
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-s);
    --margin: var(--padding-l) 0;

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
        --margin: var(--padding-m) 0;
    }
}

.block-image-text {
    color: var(--primary-color);

    @include font-sans-base();
    padding: 0 var(--padding-h);
    margin: var(--margin);
    
    .text {
        margin: 0 auto;
        max-width: var(--max-width);

        p {
            &:not(:last-child) {
                margin-bottom: 1.5em;
            }
        }
    }

    .img {
        width: 100%;

        figure {
            display: flex; 
            justify-content: center;
        }

        img,
        video {
            height: 120%;
            width: auto;
            left: auto;
        }
    }

    @media (max-width: $smartphone) {
        .img {
            margin-bottom: var(--padding-xs);
        }
    }

    @media (min-width: $smartphone) {
        display: flex;
        gap: var(--padding-m);
        justify-content: space-between;
        
        &:not(.--invert) {
            flex-direction: row-reverse;
        }

        .img {
            max-width: rem(802);
        }

        .text {
            //padding-top: var(--padding-s);
        }
    }
}
