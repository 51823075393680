.block-video-player {
    --margin: var(--padding-xl) 0;
    --padding: var(--padding-s);

    @media (max-width: $smartphone) {
        --margin: var(--padding-xl) 0 var(--padding-xxxl);
        --padding-h: var(--padding-xs);
    }
}
.block-video-player {
    padding: var(--padding);
    margin: var(--margin);

    > figure {
        position: relative;
        @include aspect-ratio(16,9);

        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    .--unmuted & {
        [data-mute] {
            display: none;
        }
    }

    .--pause & {
        [data-pause] {
            display: none;
        }
    }

    .--play & {
        [data-play] {
            display: none;
        }
    }
}
