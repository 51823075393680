.form {
    --input-height: calc(var(--font-size-xl) * var(--line-height-serif));
}

.form {
    @include font-sans-s();

    margin-left: auto;
    margin-right: auto;
    max-width: rem(900);

    input,
    label,
    textarea,
    select,
    fieldset {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        outline: none;
        margin: 0;
        -webkit-appearance: none;
    }

    textarea {
        height: 100%;
        resize: none;
        width: 100%;
    }

    select,
    input {
        @include font-serif-base();

        background: transparent;
        color: var(--primary-color);
        cursor: pointer;

        &:not([type='checkbox']) {
            height: var(--input-height);
            // line-height: var(--input-height);
            width: 100%;
        }

        option {
            @include font-sans-s();
        }
    }
    
    ::placeholder {
        color: var(--primary-color);
        opacity: 1;
    }

    ::-ms-input-placeholder {
        color: var(--primary-color);
    }

    // label {
    //     color: var(--white);
    //     left: 0;
    //     line-height: var(--input-height);
    //     position: absolute;
    //     pointer-events: none;
    //     top: 50%;
    //     transform: translate3d(0, -50%, 0);
    //     transform-origin: center left;
    //     transition: 0.2s ease-in-out;
    // }

    &__wrapper:not(:last-child) {
        margin-bottom: var(--padding-m);
    }

    fieldset {
        border-bottom: 1px solid currentColor;
        // height: calc(rem(12) + var(--input-height));
        padding: 0 0 var(--padding-xs);
        position: relative;

        &:not(:last-child) {
            margin-bottom: var(--padding-xs);
        }
        
        &.--textarea {
            height: rem(120);

            // label {
            //     top: var(--input-height) / 2;
            // }
        }

        &.--select {
            &::before {
                color: currentColor;
                content: '+';
                height: 100%;
                line-height: 180%;
                position: absolute;
                pointer-events: none;
                right: 0;
                top: 0;
                transform: scale(1.5);
            }
        }

        &.--checkbox {
            border: 0;
            margin: 0;
            padding: 0;

            &.--focus,
            &.--success,
            &.--error {
                label {
                    transform: none;
                    // top: 0;
                }
            }

            &.--success label {
                border-color: var(--color-success);
            }
            
            &.--error label {
                border-color: var(--color-error);
            }

            label {
                cursor: pointer;
                pointer-events: initial;
                position: static;
                transform: none;
                // top: 0;
            }
        }

        &.--success {
            border-color: var(--color-success);
        }

        &.--error {
            border-color: var(--color-error);
        }
    }

    .hidden {
        display: none;
    }

    button {
        border: 0;
        background-color: transparent;
        padding: 0;

        @include font-serif-l();
        color: var(--primary-color);
        border-bottom: 1px solid var(--primary-color);

        &[disabled] {
            opacity: .5;
            pointer-events: none;
        }

        &.--sending .button {
            opacity: .5;
            pointer-events: none;
        }
    }

    @media (max-width: $smartphone) {
        &__footer {
            margin-top: var(--padding-m);
            display: flex;
            justify-content: center;
        }
    }

    @media (min-width: $smartphone) {
        &__footer {
            margin-top: var(--padding-l);
        }
    }
}