.block-highlight-text {
    --height-vh: 100vh;
    --height-svh: 100dvh;
    --height: var(--height-svh, var(--height-vh));

    .--safari & {
        --height: var(--height-vh);
    }
    
    --scroll-height: 200vh;

    --padding-v: var(--padding-xxxxxxl);
    --padding-h: var(--padding-s);

    --max-width: #{rem(920px)};
    
    &.--title {
        --max-width: #{rem(1156)};
        --padding-v: var(--padding-xxxxxl);
        --padding-h: var(--padding-xs);
    }
    
    @media (max-width: $smartphone) {
        --max-width: 90vw;
        --padding-v: var(--padding-xxxl);
        --padding-h: var(--padding-xs);
        
        &.--title {
            --padding-v: var(--padding-xxxl);
            --max-width: 90vw;
        }
    }
}

.block-highlight-text {
    height: var(--scroll-height);

    .wrapper {
        @include z-index(1);
        position: relative;
        padding: var(--padding-v) var(--padding-h);
        color: var(--primary-color);
        height: 100vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        p {
            &:not(:last-child) {
                margin-bottom: 1.5em;
            }
        }

        > * {
            margin: 0 auto;
            max-width: var(--max-width);
            text-align: center;
        }
    }

    .link-underline {
        @include font-sans-s();
        margin-top: var(--padding-m);
    }

    &:not(.--title) {
        @include font-sans-l();
    }
    
    &.--title {
        @include font-serif-l();
    }

    .bg {
        @include z-index(0);
        left: 0;
        position: absolute;
        top: 0;
        height: 100vh;
        width: 100%;
        overflow: hidden;
        
        figure {
            position: relative;
            width: 100%;
            height: 100%;
            filter: blur(50px);
            /*display: flex;
            justify-content: center;*/
            
            img,
            video {
                position: absolute;
                top:0;
                left: 0;
                width: 100%;
                height: 100%;
               
                object-fit: cover;
                object-position: center;
                transform-origin: center center;
            }
        }
    }

    @media (max-width: $smartphone) {
        margin: var(--padding-v) 0 ;

        .bg {
            position: sticky;

            display: flex;
            align-items: center;
            justify-content: center;

            .text {
                position: absolute;

                p {
                    &:not(:last-child) {
                        margin-bottom: 1.5em;
                    }
                }
    
                margin: 0 auto;
                max-width: var(--max-width);
                text-align: center;
            }       
        }
    }
}
