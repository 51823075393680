.block-gallery {
    --width: 100%;
    --height-vh: 100vh;
    --height-svh: 100dvh;
    --height: var(--height-svh, var(--height-vh));
    
    .--safari & {
        --height: var(--height-vh);
    }

    --padding: var(--padding-l);

    --width-desc: #{rem(250)};
    --margin-desc: .8em;
    
    @media (max-width: $smartphone) {
        --padding: var(--padding-m);
        --width-desc: 100%;
        --margin-desc: 0;
    }
}

.block-gallery {
    width: var(--width);
    user-select: none;
    height: var(--height);
    
    &__slider {
        padding: var(--padding) 0;
        height: var(--height);
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        
        cursor: grab;    
        &:active {
            cursor: grabbing;
        }
    
        > .holder {
            order: 1;
            position: relative;
            height: 100%;
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
        }
    }
    
    &__item {
        position: relative;
        flex: 0 0 var(--width);
        width: var(--width);
        height: 100%;
        overflow: hidden;
    
        svg {
            height: 100%;
            width: auto;
        }
    
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }
    }

    .close {
        position: absolute;
        top: var(--padding-xs);
        left: var(--padding-xs);
        @include z-index(1);
        @include font-sans-s(1);
    }
    
    &__info {
        @include z-index(1);
        @include font-sans-s();
        position: absolute;
        width: 100%;
        height: auto;
        bottom: 0;
        padding: var(--padding-xs);
        pointer-events: none;
               
        h1 {
            @include font-sans-s();
        }
        
        .desc {
            @include font-sans-s();
            display: flex;
            
            //flex-direction: column;

            @media (max-width: $smartphone) {
                justify-content: space-between;
                align-items: flex-end;

                p {
                    flex: 0 0 auto;
                    max-width: 50%;
                    text-align: right;
                }
            }

            > h1 {
                flex: 0 0 rem(60);
                margin: 0;

                @media (max-width: $smartphone) {
                    flex: 0 0 50%;
                }
            }

            width: var(--width-desc);
            margin: 0 0 var(--margin-desc);
        }
    }
    
    &__controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        pointer-events: none;

        @include font-sans-s(1);
        
        button {
            pointer-events: all;
            @include basic-a();
            @include font-sans-s(1);
            background-color: transparent;
            padding: 0;
        }

        > div {
            display: flex;
        }

        .buttons {
            gap: var(--padding-xs);
        }
    }

    @media (max-width: $smartphone) {
        > div {
            height: 100%;
            width: auto;
            overflow: hidden;
        }

        .holder {
            -webkit-overflow-scrolling: touch;
            overflow: hidden;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            gap: var(--padding-xs);
            height: 100%;
            padding-right: var(--padding-xs);

            &::-webkit-scrollbar {
                display: none;
            }
        }
        
        &__item {
            width: auto;
            height: 100%;
            flex: 0 0 90vw;

            display: flex;
            align-items: center;
            
            scroll-snap-align: center;
            
            &:first-child {
                padding-left: var(--padding-xs);
            }
        }
    }

    @media (min-width: $smartphone) {
        .close {
            top: var(--padding-s);
            left: var(--padding-s);
        }

        &__info {
            padding: var(--padding-s);
        }

        &__item {
            figure {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}