.block-title-image-text-link {
    --img-width: #{rem(448px)};
    --max-width: #{rem(930px)};
    --padding-v: var(--padding-xxxxl);
    --padding-h: var(--padding-xxxxl);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxxl);
        --padding-h: var(--padding-xs);
        --img-width: 100%;
    }

    
}

.block-title-image-text-link {
    color: var(--primary-color);
    text-align: center;
    padding: 0 var(--padding-h);
    margin: var(--padding-v) 0;
    
    .intro {
        margin-bottom: var(--padding-s);
        text-align: left; 
    
        h2 {
            @include font-serif-base();
        }
    
        .label {
            @include font-sans-s();
        }
    }
    
    .img {
        width: var(--img-width);
        margin: 0 auto;
        margin-bottom: var(--padding-m);
        
        figure {
            display: flex; 
            justify-content: center;
        }
        
        img,
        video {
            height: 120%;
            width: auto;
            left: auto;
        }
    }
    
    .text {
        @include font-sans-l();
        margin: 0 auto;
        max-width: var(--max-width);
        text-align: center;
        margin-bottom: var(--padding-s);

        p {
            &:not(:last-child) {
                margin-bottom: 1.5em;
            }
        }
    }

    .link-underline {
        @include font-sans-base();
    }

    @media (max-width: $smartphone) {
        .intro {
            .label {
                margin-bottom: var(--padding-xxs);
            }
        }
    }

    @media (min-width: $smartphone) {
        .intro {
            display: flex;
            gap: var(--padding-xxl);
            margin-bottom: var(--padding-xxxxl);

            .label {
                margin-bottom: 0;
                flex: 0 1 rem(145);
            }
            
            h2 {
                flex: 0 1 var(--img-width); 
            }
        }

        .img {
            margin-bottom: var(--padding-xxl);
        }

        .text {
            margin-bottom: var(--padding-m-2);
        }
    }
}
