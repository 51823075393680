#FluidCursor {
    --offset-y: #{rem(-16)};
    --offset-x: #{rem(20)};
    position: fixed;
    @include z-index($z-index-cursor);
    top: var(--offset-y);
    left: var(--offset-x);
    pointer-events: none;
}

.__touch #FluidCursor {
    display: none;
}
