.block-news-list {
    --padding-v: var(--padding-xxxxl);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxl);
        --padding-h: var(--padding-xs);
    }
}

.block-news-list {
    padding: var(--padding-v) var(--padding-h);
    
    .list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--padding-xs);
    }

    @media (max-width: $smartphone) {
        margin-top: var(--padding-xxl);
    }

    @media (min-width: $smartphone) {
        .list {
            grid-template-columns: repeat(4, 1fr);
            gap: var(--padding-s);
        }
    }
}
